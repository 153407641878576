import clinicInfoList from './Info'

export type DataType =
  | 'title'
  | 'value'
  | 'lineBtnValue'
  | 'name'
  | 'footerOptionName'
  | 'primaryColor'
  | 'logoImageUrl'
  | 'caseBackgroundImageUrl'
  | 'address'
  | 'tel'
  | 'socialMedia_fb'
  | 'socialMedia_ig'
  | 'socialMedia_line_info'
  | 'socialMedia_line_footer'
  | 'email'
  | 'route'

interface Props {
  location?: string
  value?: DataType
}

// 比對傳進的診所順序拿相對應的所需資訊
const getClinicInfo = (props: Props) => {
  const { location, value } = props

  const ClinicList = clinicInfoList.map(item => item.clinics).flat()
  const ClinicIndex = ClinicList.findIndex(clinic => clinic.value === location)
  return ClinicList[ClinicIndex][`${value}`]
}

export { getClinicInfo }
