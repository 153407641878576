import { Grid } from '@chakra-ui/core'
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import consultImage from '../../assets/static/consult/consult3.jpg'
import child from '../../assets/static/service/child.jpg'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import Collapse from '../../routes/service/components/Collapse'
import ContentSection from '../../routes/service/components/ContentSection'
import ListItem from '../../routes/service/components/ListItem'
import FluorideSection from '../service/components/Fluoride'
import SealantsSection from '../service/components/Sealants'

const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`
const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const IntroText = styled.div`
  padding-top: 24px;
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`

/** 兒童牙醫 */
const Child = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.child' }),
      path: '/service/child'
    }
  ]

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "什麼時候開始做溝隙封填最好?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "通常在6~8歲期間，小朋友的第一顆大臼齒就會長出來（即第一顆恆牙），不過容易被誤認為是乳牙而輕忽照顧，尤其這時候的小朋友其實還不太會刷牙，因此造成蛀牙的機率較高，建議在這個時期至牙科診所進行溝隙封填，並在完成後定期檢查。"
        }
      },
      {
        "@type": "Question",
        "name": "做完溝隙封填就不會蛀牙嗎？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "溝隙填平後會使齒面變得光滑易清潔，除了能夠防止外部細菌，也能夠阻絕溝隙內原有細菌的營養來源，減少蛀牙機率，不過若是沒有配合良好的清潔習慣，在樹酯沒有覆蓋的部分還是有機會受到食物殘渣的侵蝕，引發蛀牙。因此，還是會建議每3~6個月定期回診所檢查哦！"
        }
      },
      {
        "@type": "Question",
        "name": "溝隙封填跟補牙有什麼不一樣？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "溝隙封填與補牙皆為針對蛀牙處理的醫療行為，不過溝隙封填是將牙齒表面的溝痕給予適當的材料包覆，屬於預防性醫療行為；而補牙是將已經遭蛀蝕的區域挖除，再填補牙科材料復型，屬於治療性醫療行為。兩者不同哦！"
        }
      }
    ]
  }

  return (
    <ServiceLayout consultImage={consultImage} pageBannerInfo={formatMessage({ id: 'banner.child' })} routes={routes} {...props}>
      <Seo
        title='【6歲以下免費塗氟】給小小孩最安心舒適的看牙時光-日不落牙醫集團'
        metaDescription='孩童口腔清潔不易，蛀牙發生率高建議每三個月定期檢查，遍布台北、新竹、台中、台南的日不落兒童牙醫針對乳牙齲齒、溝隙（窩溝）封填、兒童根管治療及預防性治療提供專業服務。'
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>
      <StyledContentTitle>{formatMessage({ id: 'service.child' })}</StyledContentTitle>
      <ContentSection>
        <p>
          孩童口腔清潔不易，蛀牙發生率高建議<strong>每三個月定期檢查</strong>
          ，了解孩童口腔健康是否完善，日不落兒童牙醫針對乳牙齲齒、兒童根管治療及預防性治療，照顧小小孩的口腔健康，打好未來健康牙齒的根基！
        </p>
      </ContentSection>

      <ContentSection id='patch' imageUrl={child} imagePosition='fluid' imageAlt='蒔光小小牙醫'>
        <IntroText>
          <p>
            口腔健康需要從小維持，為了降低小小孩內心恐懼，日不落牙醫集團醫師以親切溫柔的態度舒緩小小孩緊張害怕的情緒，消除小小孩對看牙的恐懼，提供專業貼心的體驗讓小小孩適應看牙時光。
          </p>
        </IntroText>
      </ContentSection>
      <ContentSection title='塗氟'>
        <p>牙齒表面塗抹氟化物，提高牙齒的抗酸性，幫助牙齒再礦化，抑制牙菌斑生成，強化齒質預防牙齒蛀牙。目前健保有給付 6 歲以下孩童半年一次的塗氟。</p>
        <FluorideSection />
      </ContentSection>
      <ContentSection title='塗氟結束後要注意'>
        <ListGroup>
          <Grid rowGap='16px' autoFlow='row'>
            <ListItem content='30 分鐘內不可喝水、吃東西、漱口，以免降低效用。' />
            <ListItem content='含氟製品也忌與牛奶混著吃，以免氟與鈣結合而影響氟的吸收。' />
          </Grid>
        </ListGroup>
      </ContentSection>
      <ContentSection title='溝隙（窩溝）封填'>
        <p>
          剛萌發的臼齒牙齒咬合面的凹溝，容易造成食物和細菌的累積，清潔不易長時間會造成蛀牙。溝隙封填是使用<strong>流動性</strong>
          的補牙材料，將細小溝隙封填起來，使牙齒咬合面平滑降低清潔死角，減少蛀牙的機率。
        </p>
        <SealantsSection />
      </ContentSection>

      <QuestionSection>
        <ContentSection title='常見問題'>
          <Collapse title='什麼時候開始做溝隙封填最好?'>
            <p>
              通常在<strong>6~8歲期間</strong>
              ，小朋友的第一顆大臼齒就會長出來（即第一顆恆牙），不過容易被誤認為是乳牙而輕忽照顧，尤其這時候的小朋友其實還不太會刷牙，因此造成蛀牙的機率較高，建議在這個時期至牙科診所進行溝隙封填，並在完成後定期檢查。
            </p>
          </Collapse>
          <Collapse title='做完溝隙封填就不會蛀牙嗎？'>
            <p>
              溝隙填平後會使齒面變得光滑易清潔，除了能夠防止外部細菌，也能夠阻絕溝隙內原有細菌的營養來源，減少蛀牙機率，不過若是沒有配合良好的清潔習慣，在樹酯沒有覆蓋的部分還是有機會受到食物殘渣的侵蝕，引發蛀牙。因此，還是會建議每
              <strong>3~6個月</strong>定期回診所檢查哦！
            </p>
          </Collapse>
          <Collapse title='溝隙封填跟補牙有什麼不一樣？'>
            <p>
              溝隙封填與補牙皆為針對蛀牙處理的醫療行為，不過溝隙封填是將牙齒表面的溝痕給予適當的材料包覆，屬於<strong>預防性醫療行為</strong>
              ；而補牙是將已經遭蛀蝕的區域挖除，再填補牙科材料復型，屬於<strong>治療性醫療行為</strong>。兩者不同哦！
            </p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}
export default Child
