import { CloudOutlined } from '@ant-design/icons'
import { Editor } from '@tinymce/tinymce-react'
import { Button, message } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import DashboardImage from '../../assets/images/dashboard_image.svg'
import { Hsinchu, Taichung, Tainan, Taipei } from '../../constants/newColor'
import { articleStyleInEditorContentStyle } from '../../constants/style'
import GqlUpload from '../GqlUpload'

const tinyApiKey = process.env.TINY_API_KEY

const Container = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  padding: 0 20px;
`
const Notice = styled.span`
  color: rgb(133, 133, 133);
  display: flex;
  font-weight: 300;
  margin: 4px 0;
`

const EmphasizeText = styled.span`
  color: rgb(87, 87, 87);
  font-weight: 800;
`

const CustomizedButton = styled(Button)`
  margin: 8px;
`

const EditorContainer = styled.div`
  cursor: 'text';
  min-height: 400px;
  padding-bottom: 20px;

  .DraftEditor-root {
    position: relative;
  }
  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: #999;
  }
`
const ColorStyleWrapper = styled.div`
  color: rgb(190, 188, 188);
  font-weight: 300;
  margin: 16px 0;
  line-height: 40px;
  border-right: 1px solid rgb(221, 221, 221);
`

interface ColorProps {
  color?: string
}

const Mark = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  &:before {
    content: '';
    background-color: ${props => props.color};
    display: block;
    width: 20px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
  }
`

const PreInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const GetPicturePath = styled.div`
  margin: 0 32px;
`

interface BlockProps {
  display: boolean
}
const Block = styled.div<BlockProps>`
  max-width: 358px;
  padding: 8px;
  margin: 16px;
  border: 1px solid rgb(221, 221, 221);
  display: ${props => (props.display ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  word-break: break-all;
`

const ImgAndPath = styled.div`
  display: flex;
  align-items: center;
  margin: 4px auto;
`

const Img = styled.img`
  height: 48px;
  width: 48px;
  margin-right: 8px;
  object-fit: cover;
  flex-shrink: 0;
`

interface Props {
  onChange?: (content: string) => void
  value?: string
}

const RichTextEditor = (props: Props) => {
  const { onChange, value = '' } = props
  const [picturePath, setPicturePath] = useState('')
  const [display, setDisplay] = useState(false)

  const handleEditorStateChange = event => {
    const content = event.target.getContent()
    onChange?.(content)
  }

  const handlePicturePath = value => {
    setPicturePath(value)
    setDisplay(true)
  }
  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text)
    message.info('文字已複製到剪貼簿！')
  }

  return (
    <Container>
      <PreInfo>
        <ColorStyleWrapper>
          <Mark color={Taipei}>日亞美 {Taipei}</Mark>
          <Mark color={Hsinchu}>橙蒔 {Hsinchu}</Mark>
          <Mark color={Taichung}>日蒔 {Taichung}</Mark>
          <Mark color={Tainan}>蒔光 {Tainan}</Mark>
        </ColorStyleWrapper>
        <GetPicturePath>
          <Notice>
            內文<EmphasizeText>插入圖片</EmphasizeText>請先 ❶ 取得圖片網址 ❷ 複製並貼上至
            <img src={DashboardImage} alt='' />
            原始碼欄
          </Notice>
          <Notice>（ 取得時間過久/失敗時，請檢查檔案是否過大 ）</Notice>
          <GqlUpload listType='picture' setPicturePath={handlePicturePath} showUploadList={false}>
            <CustomizedButton icon={<CloudOutlined />} type='primary'>
              取得圖片網址
            </CustomizedButton>
          </GqlUpload>
        </GetPicturePath>
        <Block display={display}>
          <ImgAndPath>
            <Img src={picturePath} alt='' />
            {picturePath}
          </ImgAndPath>
          <Button size='small' onClick={() => handleCopyText(picturePath)}>
            點擊複製
          </Button>
        </Block>
      </PreInfo>
      <EditorContainer>
        <Editor
          apiKey={tinyApiKey}
          init={{
            block_formats: 'Paragraph=p;Heading 1=h2;Heading 2=h3;Heading 3=h4;Preformatted=pre',
            branding: false,
            height: 500,
            menubar: false,
            language: 'zh_TW',
            paste_data_images: true,
            plugins: [
              'advlist autolink lists link image imagetools charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              'pagebreak',
              'emoticons'
            ],
            toolbar:
              // eslint-disable-next-line max-len
              'undo redo | formatselect | bold italic fontsizeselect forecolor backcolor | alignleft aligncenter alignright alignjustify |table bullist numlist outdent indent | link image code | emoticons | help',
            fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
            content_style: articleStyleInEditorContentStyle,
            valid_children: '+body[script]',
            extended_valid_elements: 'script[type]'
          }}
          initialValue={value}
          onChange={handleEditorStateChange}
        />
      </EditorContainer>
      <Notice> Ξ 為確保頁面存在單一 &lt;h1&gt; ，因此目前標題一設定為 &lt;h2&gt; 、標題二設定為 &lt;h3&gt; ... 以此類推 ☃ Ξ</Notice>
    </Container>
  )
}

export default RichTextEditor
