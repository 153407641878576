import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import consultImage from '../../../assets/static/consult/consult2.jpg'
import case1ImageDesktop from '../../../assets/static/service/case1_digital_desktop.png'
import case1ImageMobile from '../../../assets/static/service/case1_digital_mobile.png'
import case2ImageDesktop from '../../../assets/static/service/case2_digital_desktop.png'
import case2ImageMobile from '../../../assets/static/service/case2_digital_mobile.png'
import digital2Image from '../../../assets/static/service/digital2.jpg'
import digital3Image from '../../../assets/static/service/digital3.jpg'
import orthoCompareTableDesktop from '../../../assets/static/service/orthoCompare_table_desktop.png'
import orthoCompareTableMobile from '../../../assets/static/service/orthoCompare_table_mobile.png'
import orthodontics1Image from '../../../assets/static/service/orthodontics1.jpg'
import ServiceLayout from '../../../components/Layout/ServiceLayout'
import Seo from '../../../components/Seo'
import { ContentTitle } from '../../../components/Titles/ContentTitle'
import { space } from '../../../constants/length'
import { viewport } from '../../../constants/viewport'
import Collapse from '../components/Collapse'
import ContentSection from '../components/ContentSection'
import ComparisonOfSmilux from './ComparisonOfSmilux'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`
const ListContent = styled.blockquote`
  margin-left: 1rem;
`

const CompareImages = styled.div`
  img + img {
    margin-top: ${space.m}px;
  }
`

const DesktopCaseImage = styled.img`
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const MobileCaseImage = styled.img`
  display: none;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: block;
  }
`

/** 隱形矯正 */
const Digital = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.digital' }),
      path: '/service/digital'
    }
  ]

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "如果不想拔牙的話可以進行隱形矯正嗎？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "拔牙只是創造牙齒移動空間的一個選項，而拔牙與否改善的結果也會不太一樣。若經醫師評估後，也可選擇用IPR（鄰接面去釉）、牙弓擴張、臼齒往後移動等方式來代替拔牙獲取牙齒移動空間，以進行後續矯正治療。"
        }
      },
      {
        "@type": "Question",
        "name": "隱形矯正會痛嗎？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "任何矯正都會因為牙齒被拉扯而感覺到痛，那疼痛指數則會因每個人耐痛程度而有所不同，謠傳會吃辣得比較沒有感覺。但是SOV最大的差別在於它是服貼牙齒設計的矯正器，並沒有鋼牙的稜稜角角去刮你、戳你的肉，所以在矯正體驗上還是會舒服很多。"
        }
      },
      {
        "@type": "Question",
        "name": "如果有假牙可以進行隱形矯正嗎？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "因為SOV牙套是套著牙齒做移動，而非黏在牙面上的矯正器，所以大部分的假牙都仍可進行隱形矯正，但有些情況還是需要換成臨時假牙，詳細仍需經過醫師評估後才能確認。"
        }
      },
      {
        "@type": "Question",
        "name": "隱形矯正結束後需要配戴維持器嗎？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "不管是傳統矯正或隱形矯正，療程結束後皆需要配戴維持器。建議前半年就跟牙套一樣整天都要配戴，等待牙齒及臉頰軟組織穩定後，再減少為晚上睡覺時配戴。"
        }
      }
    ]
  }

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.digital' })}>
      <Seo
        title='隱形矯正推薦：隱適美 vs SOV舒服美 vs Zenyum-日不落牙醫集團'
        metaDescription='想矯正牙齒卻好怕痛？交給日不落牙醫集團吧！遍布台北、新竹、台中、台南，選用隱形牙套服貼牙齒舒適不刮肉，極近無痛的隱形矯正療程中讓牙齒美觀升級，隱形牙套再也不怕矯正器會刮破嘴巴！'
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>
      <StyledContentTitle>{formatMessage({ id: 'service.digital' })}</StyledContentTitle>
      <ContentSection id='digital-alignment' imageUrl={orthodontics1Image} title='SOV 數位隱形矯正' imageAlt='隱形矯正(隱形牙套)'>
        <p>
          隱形矯正解開傳統牙套的枷鎖，本院選用MIT第一品牌 <strong>『SOV數位隱形矯正系統』</strong>
          ，其利用衛福部認證許可的生物高分子材料、並經由專業牙技師設計牙套形變對牙齒施予柔和矯正力
          ，分段進行牙齒推移、扭轉，調整齒列至最佳位置，且全透明、可拆卸式的隱形牙套，讓日常生活便捷、美觀。
        </p>
      </ContentSection>
      <ContentSection>
        <CompareImages>
          <DesktopCaseImage src={case1ImageDesktop} alt='虎牙/重度凌亂' />
          <MobileCaseImage src={case1ImageMobile} alt='虎牙/重度凌亂' />
        </CompareImages>
      </ContentSection>
      <ContentSection id='smilux' imageUrl={digital2Image} imagePosition='left' title='樂齒微矯正' imageAlt='樂齒微矯正(二次矯正、隱形牙套)'>
        <p>
          <strong>『SMILUX樂齒微矯正』</strong>
          為台灣SOV舒服美推出的輕量化隱形矯正品牌，結合專屬附件施力系統、及最佳化齒列排列模式，提供了最適合前排牙齒美觀微調的隱形矯正系統。
        </p>
      </ContentSection>
      <ContentSection>
        <CompareImages>
          <DesktopCaseImage src={case2ImageDesktop} alt='前牙齒性反咬' />
          <MobileCaseImage src={case2ImageMobile} alt='前牙齒性反咬' />
        </CompareImages>
      </ContentSection>
      <ContentSection title='SOV舒服美與SMILUX樂齒的不同'>
        <ComparisonOfSmilux />
      </ContentSection>
      <ContentSection title='與他牌矯正的差異'>
        <CompareImages>
          <DesktopCaseImage src={orthoCompareTableDesktop} alt='與其他矯正品牌差異' />
          <MobileCaseImage src={orthoCompareTableMobile} alt='與其他矯正品牌差異' />
        </CompareImages>
      </ContentSection>
      <ContentSection imageUrl={digital3Image} title='南北醫師同步看診' imageAlt='南北醫師同步看診'>
        <p>
          日不落牙醫集團提供南北醫師同步看診的服務，讓在矯正療程進行到一半卻因為課業、工作或其他因素而需要北/南漂的患者無需至原診所回診，提早告知即可轉診至未來居住地的集團診所，大幅減少回診所需的時間成本，不讓矯正療程因此延宕。
        </p>
        <p>* 轉診需提前先告知原診所，替你安排預約</p>
      </ContentSection>
      <ContentSection title='治療過程'>
        <p>
          <strong>❶ 線上諮詢</strong>
          <ListContent>透過FB粉絲頁、 Line@、或留下聯絡方式進行即時諮詢，了解自己適合全口矯正/局部微矯正。</ListContent>
        </p>
        <p>
          <strong>❷ 到診深度評估</strong>
          <ListContent>攜帶健保卡及掛號費進行約診，由專業醫師團隊聚焦於齒列狀況如何透矯正進行改善。</ListContent>
        </p>
        <p>
          <strong>❸ 數位模擬計畫</strong>
          <ListContent>若想進一步了解隱形矯正療程如何進行，需自費5000元，診所將安排口腔3D建模、並傳送給牙套原廠。(若後續確定進行矯正療程，5000元將折抵於療程中)。</ListContent>
        </p>
        <p>
          <strong>❹ 聽取評估報告</strong>
          <ListContent>約兩周時間，您的數位評估報告即可完成，您將看到矯正後的預估齒列模樣、及預計療程時間。</ListContent>
        </p>
        <p>
          <strong>❺ 開始牙套療程</strong>
          <ListContent>如果報告內容符合預期，您可以準備訂金並簽署矯正療程同意書，兩周~一個月後便可開始您的隱形牙套療程。</ListContent>
        </p>
      </ContentSection>
      <QuestionSection>
        <ContentSection title='常見問題'>
          <Collapse title='如果不想拔牙的話可以進行隱形矯正嗎？'>
            <p>
              拔牙只是創造牙齒移動空間的一個選項，而拔牙與否改善的結果也會不太一樣。若經醫師評估後，也可選擇用IPR（鄰接面去釉）、牙弓擴張、臼齒往後移動等方式來代替拔牙獲取牙齒移動空間，以進行後續矯正治療。
            </p>
          </Collapse>
          <Collapse title='隱形矯正會痛嗎？'>
            <p>
              任何矯正都會因為牙齒被拉扯而感覺到痛，那疼痛指數則會因每個人耐痛程度而有所不同，謠傳會吃辣得比較沒有感覺。但是SOV最大的差別在於它是服貼牙齒設計的矯正器，並沒有鋼牙的稜稜角角去刮你、戳你的肉，所以在矯正體驗上還是會舒服很多。
            </p>
          </Collapse>
          <Collapse title='如果有假牙可以進行隱形矯正嗎？'>
            <p>因為SOV牙套是套著牙齒做移動，而非黏在牙面上的矯正器，所以大部分的假牙都仍可進行隱形矯正，但有些情況還是需要換成臨時假牙，詳細仍需經過醫師評估後才能確認。</p>
          </Collapse>
          <Collapse title='隱形矯正結束後需要配戴維持器嗎？'>
            <p>不管是傳統矯正或隱形矯正，療程結束後皆需要配戴維持器。建議前半年就跟牙套一樣整天都要配戴，等待牙齒及臉頰軟組織穩定後，再減少為晚上睡覺時配戴。</p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default Digital
