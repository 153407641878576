import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import consultImage from '../../assets/static/consult/consult4.jpg'
import retainerTableImage from '../../assets/static/service/retainer_table.png'
import retainer1Image from '../../assets/static/service/retainer1.jpg'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { Primary, Secondary } from '../../constants/newColor'
import { line } from '../../constants/url'
import Collapse from './components/Collapse'
import ContentSection from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const SubTitle = styled.span`
  font-size: 1rem;
  font-weight: 300;
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`
const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`
const EmphasizedText = styled.span`
  color: ${Primary};
`
const Link = styled.a`
  color: ${Primary};
  :hover {
    color: ${Secondary};
  }
`
const SmiluxLink = styled.a`
  color: #a4be64;
  :hover {
    color: #c1db81;
  }
`

/** 透明維持器 */
const Retainer = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.retainer' }),
      path: '/service/retainer'
    }
  ]

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "舒服力透明維持器的價格是？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "3副12800元。"
        }
      },
      {
        "@type": "Question",
        "name": "透明維持器需要配戴多久呢？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "矯正結束第一年，建議全天候配戴維持器，僅在吃飯時候拆卸下來、並放在收納牙套盒中。矯正結束第二年，由醫生來判斷最適合您的配戴時長，建議仍維持每天晚上持續配戴。"
        }
      },
      {
        "@type": "Question",
        "name": "我很久以前有做過傳統矯正，還可以重新配戴透明維持器嗎？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "如果您已經很久沒有配戴維持器了，或是能夠感覺到您的牙齒已經與療程結束時的位置不同，您可以聯絡我們，SOV舒服美將協助您安排諮詢、重新製作透明維持器、或是進行樂齒微矯正二次矯正療程。"
        }
      }
    ]
  }
  
  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.retainer' })}>
      <Seo
        title='【透明維持器三副12,800】牙齒矯正守門員-日不落牙醫集團'
        metaDescription='維持器可以去別家做嗎？維持器要戴多久？維持器該如何清潔保養？現在於台北、新竹、台中、台南的日不落牙醫集團訂購舒服力透明維持器三副12,800，還可以加選「防磨牙牙套」一組，立刻量身訂做你的維持器。'
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>
      <StyledContentTitle id='title'>
        <span>{formatMessage({ id: 'service.retainer' })}</span>
        <SubTitle>(3副12800元)</SubTitle>
      </StyledContentTitle>
      <ContentSection title='何時需要使用透明維持器？'>
        <p>
          無論是傳統矯正、SOV舒服美、樂齒微矯正或其他隱形矯正治療，基本上在療程結束後都會建議使用維持器。
          <br />
          其目的，主要是使牙齒固定在矯正後的理想位置，避免在牙齒周遭的骨頭及牙齦尚未穩定的情況下，再度移位回治療前的凌亂排序，讓漫長的治療過程幻化成泡影。透過正確保存與持續使用，維持器將成為您在矯正賽場上的守門員，讓您持續保有最美好的笑容！
        </p>
      </ContentSection>
      <ContentSection imageUrl={retainer1Image} title='SOV舒服力透明維持器' imageAlt='SOV舒服力透明維持器'>
        <p>
          SOV舒服力透明維持器擁有<EmphasizedText>台灣食藥署TFDA醫療器材認證</EmphasizedText>
          許可，原料成分安全有保障。並提供<EmphasizedText>數位建檔</EmphasizedText>
          服務，在配戴正常的情況下，再次製作維持器時將不需要重製齒模，大幅減少更換時的時間成本。現在訂購舒服力，還可以免費加選<EmphasizedText>「防磨牙牙套」</EmphasizedText>一組！
        </p>
      </ContentSection>
      <ContentSection
        imageUrl={retainerTableImage}
        title='「透明維持器」與「傳統維持器」比較說明'
        imagePosition='fluid'
        imageAlt='「透明維持器」與「傳統維持器」比較說明'
        borderInvisible
      >
        <p>備註：因各種牙齒情況有其適用的維持器材質，建議您和醫師討論，衡量自身的狀況，選擇適合自己的維持器。</p>
      </ContentSection>
      <ContentSection title='配戴透明維持器注意事項'>
        <ListGroup>
          <ListItem content='請不要在配戴維持器時食用任何食物' />
          <ListItem content='維持器離開口內後，請放入專用盒子裡，切勿隨意放入口袋、書包、衛生紙中、桌上及浴室洗手檯上' />
          <ListItem content='如果維持器不見一定要儘快回診所並重新製作維持器，避免整齊的牙齒跑掉' />
        </ListGroup>
      </ContentSection>
      <QuestionSection>
        <ContentSection title='透明維持器 常見問題'>
          <Collapse title='舒服力透明維持器的價格是？'>
            <p>3副12800元。</p>
          </Collapse>
          <Collapse title='透明維持器需要配戴多久呢？'>
            <p>矯正結束第一年，建議全天候配戴維持器，僅在吃飯時候拆卸下來、並放在收納牙套盒中。</p>
            <p>矯正結束第二年，由醫生來判斷最適合您的配戴時長，建議仍維持每天晚上持續配戴。</p>
          </Collapse>
          <Collapse title='我很久以前有做過傳統矯正，還可以重新配戴透明維持器嗎？'>
            <p>
              如果您已經很久沒有配戴維持器了，或是能夠感覺到您的牙齒已經與療程結束時的位置不同，您可以<Link href={line}>聯絡我們</Link>
              ，SOV舒服美將協助您安排諮詢、重新製作透明維持器、或是進行<SmiluxLink href='/service/digital#smilux'>樂齒微矯正</SmiluxLink>二次矯正療程。
            </p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default Retainer
