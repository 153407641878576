import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import consultImage from '../../assets/static/consult/consult4.jpg'
import case1ImageDesktop from '../../assets/static/service/case1_childOrtho_desktop.png'
import case1ImageMobile from '../../assets/static/service/case1_childOrtho_mobile.png'
import case2ImageDesktop from '../../assets/static/service/case2_childOrtho_desktop.png'
import case2ImageMobile from '../../assets/static/service/case2_childOrtho_mobile.png'
import childOrthoTableImage from '../../assets/static/service/childOrtho_table.png'
import childOrtho1Image from '../../assets/static/service/childOrtho1.png'
import ChildOrthoIndications1 from '../../assets/static/service/childOrthoIndications1.png'
import ChildOrthoIndications2 from '../../assets/static/service/childOrthoIndications2.png'
import ChildOrthoIndications3 from '../../assets/static/service/childOrthoIndications3.png'
import step1Image from '../../assets/static/service/step1.svg'
import step2Image from '../../assets/static/service/step2.svg'
import step3Image from '../../assets/static/service/step3.svg'
import { GridLayout } from '../../components/Grid'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { H3 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import Collapse from './components/Collapse'
import ContentSection from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const SubTitle = styled.span`
  font-size: 1rem;
  font-weight: 300;
`
interface ImgProps {
  maxWidth?: string | number
}
const Img = styled.img<ImgProps>`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  height: auto;
  margin: 0 auto;
`
const HowToStartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space.xl}px;
  @media (max-width: ${viewport.tablet}px) {
    margin-top: 0;
  }
`
const H3Title = styled.h3`
  ${H3}
  text-align: left;
  margin-bottom: ${space.m}px;
  @media (max-width: ${viewport.tablet}px) {
    text-align: center;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  margin-right: ${space.m}px;

  @media (max-width: ${viewport.tablet - 1}px) {
    margin-bottom: ${space.m}px;
  }
`
const IndicationsImage = styled.img``
const IndicationsImageWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${viewport.tablet - 1}px) {
    flex-direction: column;
  }
  & > ${ImageWrapper}:nth-child(3) {
    margin-right: 0;
  }
`
const CompareImages = styled.div`
  img + img {
    margin-top: ${space.l}px;
  }
`
const DesktopCaseImage = styled.img`
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const MobileCaseImage = styled.img`
  display: none;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: block;
  }
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`
const ListContent = styled.blockquote`
  margin-left: 1rem;
`

const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`

/** 兒童矯正 */
const ChildOrtho = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.childOrtho' }),
      path: '/service/childOrtho'
    }
  ]

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "兒童矯正的重點是什麼？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "透過兒童早期矯正，我們可以引導牙齒正確生長、改善咬合關係、調整肌肉功能、矯正骨頭生長畸形，進而促進面部外觀的正常發育。"
        }
      },
      {
        "@type": "Question",
        "name": "何時是兒童矯正介入的最好時機？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "6 ~ 7 歲剛換完下排門牙的時候，就是兒童矯正介入的最佳時機。"
        }
      },
      {
        "@type": "Question",
        "name": "兒童矯正與成人矯正的差異是什麼？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "兒童矯正與成人矯正最大的不同在於『骨頭生長發育的潛力』，當早期治療有骨骼發育的優勢時，九成以上可進行不拔牙矯正、並改善顎骨上下差距過大問題（骨性暴牙、骨性戽斗）。不用等到成人矯正的時候，只能選擇拔牙、或進行正顎手術。"
        }
      },
      {
        "@type": "Question",
        "name": "配戴裝置是否感到疼痛？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "配戴初期牙齒酸痛屬正常現象，若認真配戴，不適感會慢慢緩解。"
        }
      },
      {
        "@type": "Question",
        "name": "矯正完成需要多久時間？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "配戴時間將因病患配合度有所差異，若認真配戴將大約於1~2年完成第一階段的骨型矯正積極介入治療。後續階段就只要睡覺前 1 - 2 小時、及睡覺時配戴，類似維持器的概念、去維持骨骼正常生長。"
        }
      },
      {
        "@type": "Question",
        "name": "兒童為什麼該進行早期矯正？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "孩子的問題其實比你看到的還多！且孩子的顱顏部在生長過程中的問題，若是不糾正、等到長大定型後往往是不可逆的！早期矯正可以讓孩子長大後擁有健康的身體、好看的外表，找不到不該進行兒童矯正的理由！"
        }
      }
    ]
  }

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.childOrtho' })}>
      <Seo
        title='別讓孩子輸在起跑線，不拔牙、不正顎手術-日不落兒童矯正專家'
        metaDescription='你是否注意到7~14歲兒童的牙齒排列不整齊、或者暴牙？還是中臉鼻子塌陷、下巴越來越長？這些問題通通可以透過台北、新竹、台中、台南的日不落兒童矯正專家幫您解決，還給孩子好看的外表。'
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>
      <StyledContentTitle id='title'>
        <span>兒童矯正牙套</span>
        <SubTitle>(7 ~ 14歲)</SubTitle>
      </StyledContentTitle>
      <ContentSection imageUrl={childOrtho1Image} imageAlt='日不落牙醫集團-兒童矯正' imagePosition='left'>
        <p>
          「一定要等到長大後拔 4 顆牙才能矯正嗎？」
          <br />
          「小時候的問題，不會因為長大而變好！」
          <br />
          「兒童時期看到的問題不處理，長大等到骨頭定形後會更麻煩！」
          <br />
          <br />
          你是否注意到您孩子的牙齒排列不整齊、或者暴牙？還是中臉鼻子塌陷、下巴越來越長？這些問題可能會導致孩子長大後更大的問題、甚至影響長大後的面部發展。
        </p>
      </ContentSection>
      <ContentSection>
        <p>
          因此，根據美國矯正協會建議：『孩子應在<strong> 7 ~ 14 歲的矯正黃金期、換完下排門牙乳牙</strong>
          後，開始接受適當的早期矯正治療。』從源頭解決問題，讓二次矯正機率大幅降低。且得益於早期治療有骨骼發育的優勢，九成以上可進行<strong>不拔牙矯正</strong>
          ，或是避免長大後的正顎手術。
        </p>
      </ContentSection>
      <ContentSection title='日不落兒童矯正三階段'>
        <GridLayout templateColumns={['100%']} rowGap={['32px', '24px']}>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step1Image} alt='step1' />
            <HowToStartWrapper>
              <H3Title>第一階段 骨型矯正</H3Title>
              <p>
                透配戴<strong>擴弓器</strong>、<strong>推骨器</strong>、或<strong>擋舌器</strong>
                等，進行骨骼形狀的矯正、以及牙齒的前後排列調整。利用骨型矯正器可以有效地改善牙齒和顎骨的不良位置，打下良好的口腔發育條件。本階段將配戴到
                <strong>前牙排列整齊</strong>為止。
              </p>
            </HowToStartWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step2Image} alt='step2' />
            <HowToStartWrapper>
              <H3Title>第二階段 上下顎骨對位</H3Title>
              <p>
                若兒童有嚴重暴牙、或戽斗（厚道）問題，利用<strong>口呼吸訓練器</strong>進行口腔肌肉訓練，藉由牙套咬合、誘導顎骨至適當位置，並改善不良的口呼吸習慣。本階段將配戴到
                <strong>第二大臼齒</strong>萌發為止。
              </p>
            </HowToStartWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step3Image} alt='step3' />
            <HowToStartWrapper>
              <H3Title>第三階段 維持骨頭正常生長</H3Title>
              <p>
                進當上下顎骨對位到一類咬合、中線對齊位置後，就可以開始配戴內含<strong>隱形牙套</strong>的口呼吸訓練器，進行牙齒角度的微調，並維持骨頭在正常發育位置。
              </p>
            </HowToStartWrapper>
          </GridLayout>
        </GridLayout>
      </ContentSection>
      <ContentSection title='適合兒童矯正的對象'>
        <IndicationsImageWrapper>
          <ImageWrapper>
            <IndicationsImage src={ChildOrthoIndications1} alt='兒童矯正範例1' />
          </ImageWrapper>
          <ImageWrapper>
            <IndicationsImage src={ChildOrthoIndications2} alt='兒童矯正範例2' />
          </ImageWrapper>
          <ImageWrapper>
            <IndicationsImage src={ChildOrthoIndications3} alt='兒童矯正範例3' />
          </ImageWrapper>
        </IndicationsImageWrapper>
        <ListGroup>
          <ListItem content='牙齒出現擁擠或重疊的狀況' />
          <ListItem content='咬合出現暴牙、開咬等情形' />
          <ListItem content='長期發生拇指吮吸、吐舌吞嚥、或口呼吸等情況發生' />
        </ListGroup>
      </ContentSection>
      <ContentSection title='兒童矯正牙套實際案例'>
        <CompareImages>
          <DesktopCaseImage src={case1ImageDesktop} alt='前牙蛀牙/變色' />
          <DesktopCaseImage src={case2ImageDesktop} alt='小兔牙（輕微凌亂）' />
          <MobileCaseImage src={case1ImageMobile} alt='前牙蛀牙/變色' />
          <MobileCaseImage src={case2ImageMobile} alt='小兔牙（輕微凌亂）' />
        </CompareImages>
      </ContentSection>
      <ContentSection
        imageUrl={childOrthoTableImage}
        imageAlt='日不落兒童矯正 vs MRC矯正 vs 隱形牙套'
        imagePosition='fluid'
        title='日不落兒童矯正 vs MRC矯正 vs 隱形牙套'
        borderInvisible
      />
      <ContentSection title='兒童矯正的諮詢流程'>
        <p>
          <strong>❶ 線上諮詢</strong>
          <ListContent>透過FB粉絲頁、 Line@、或留下聯絡方式進行即時諮詢，了解孩子適合進行的矯正方式。</ListContent>
        </p>
        <p>
          <strong>❷ 到診深度評估</strong>
          <ListContent>請攜帶健保卡及掛號費進行約診，由專業醫師團隊聚焦於齒列狀況如何透矯正進行改善。</ListContent>
        </p>
        <p>
          <strong>❸ 數位模擬計畫</strong>
          <ListContent>
            若確認要進行孩子的矯正療程，需自費5000元，診所將安排口腔3D建模、並傳送給牙套原廠。
            <br />
            (若後續確定進行矯正療程，5000元將折抵於療程中)，並準備訂金並簽署矯正療程同意書。
          </ListContent>
        </p>
        <p>
          <strong>❹ 開始牙套療程</strong>
          <ListContent>等待約兩周至一個月的時間，便可開始您孩子的矯正療程。</ListContent>
        </p>
      </ContentSection>
      <QuestionSection>
        <ContentSection title='兒童矯正牙套 常見問題'>
          <Collapse title='兒童矯正的重點是什麼？'>
            <p>透過兒童早期矯正，我們可以引導牙齒正確生長、改善咬合關係、調整肌肉功能、矯正骨頭生長畸形，進而促進面部外觀的正常發育。</p>
          </Collapse>
          <Collapse title='何時是兒童矯正介入的最好時機？'>
            <p>6 ~ 7 歲剛換完下排門牙的時候，就是兒童矯正介入的最佳時機。</p>
          </Collapse>
          <Collapse title='兒童矯正與成人矯正的差異是什麼？'>
            <p>
              兒童矯正與成人矯正最大的不同在於『骨頭生長發育的潛力』，當早期治療有骨骼發育的優勢時，九成以上可進行<strong>不拔牙矯正</strong>、並
              <strong>改善顎骨上下差距過大</strong>問題（骨性暴牙、骨性戽斗）。不用等到成人矯正的時候，只能選擇拔牙、或進行正顎手術。
            </p>
          </Collapse>
          <Collapse title='配戴裝置是否感到疼痛？'>
            <p>配戴初期牙齒酸痛屬正常現象，若認真配戴，不適感會慢慢緩解。</p>
          </Collapse>
          <Collapse title='矯正完成需要多久時間？'>
            <p>
              配戴時間將因病患配合度有所差異，若認真配戴將大約於1~2年完成第一階段的骨型矯正積極介入治療。後續階段就只要睡覺前 1 - 2
              小時、及睡覺時配戴，類似維持器的概念、去維持骨骼正常生長。
            </p>
          </Collapse>
          <Collapse title='兒童為什麼該進行早期矯正？'>
            <p>
              孩子的問題其實比你看到的還多！且孩子的顱顏部在生長過程中的問題，若是不糾正、等到長大定型後往往是不可逆的！
              <br />
              早期矯正可以讓孩子長大後擁有健康的身體、好看的外表，找不到不該進行兒童矯正的理由！
            </p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default ChildOrtho
