import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import consultImage from '../../assets/static/consult/consult4.jpg'
import allergyFeature1 from '../../assets/static/service/allergy_feature1.png'
import allergyFeature2 from '../../assets/static/service/allergy_feature2.png'
import allergyFeature3 from '../../assets/static/service/allergy_feature3.png'
import allergySymptom1 from '../../assets/static/service/allergy_symptom1.png'
import allergySymptom2 from '../../assets/static/service/allergy_symptom2.png'
import allergySymptom3 from '../../assets/static/service/allergy_symptom3.png'
import allergy1Image from '../../assets/static/service/allergy1.png'
import allergy2Image from '../../assets/static/service/allergy2.png'
import case1ImageDesktop from '../../assets/static/service/case1_allergy_desktop.png'
import case1ImageMobile from '../../assets/static/service/case1_allergy_mobile.png'
import case2ImageDesktop from '../../assets/static/service/case2_allergy_desktop.png'
import case2ImageMobile from '../../assets/static/service/case2_allergy_mobile.png'
import step1Image from '../../assets/static/service/step1.svg'
import step2Image from '../../assets/static/service/step2.svg'
import step3Image from '../../assets/static/service/step3.svg'
import { GridLayout } from '../../components/Grid'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { Grey } from '../../constants/newColor'
import { H3 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import Collapse from './components/Collapse'
import ContentSection from './components/ContentSection'
import GalleryItem from './components/GalleryItem'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const SubTitle = styled.span`
  font-size: 1rem;
  font-weight: 300;
`
const FeatureWrapper = styled.div`
  margin-bottom: ${space.xl}px;
`
const SymptomWrapper = styled.div`
  margin-top: ${space.l}px;
`
const CompareImages = styled.div`
  img + img {
    margin-top: ${space.l}px;
  }
`
const DesktopCaseImage = styled.img`
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const MobileCaseImage = styled.img`
  display: none;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: block;
  }
`
interface ImgProps {
  maxWidth?: string | number
}
const Img = styled.img<ImgProps>`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  height: auto;
  margin: 0 auto;
`
const HowToStartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space.xl}px;
  @media (max-width: ${viewport.tablet}px) {
    margin-top: 0;
  }
`
const H3Title = styled.h3`
  ${H3}
  text-align: left;
  margin-bottom: ${space.m}px;
  @media (max-width: ${viewport.tablet}px) {
    text-align: center;
  }
`
const GrayBgContent = styled.span`
  display: inline-block;
  padding: 0 ${space.m}px;
  margin: 0 ${space.m}px;
  border-radius: ${space.s}px;
  background-color: ${Grey};
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`

const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`

/** 幼兒舒敏 */
const Allergy = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.allergy' }),
      path: '/service/allergy'
    }
  ]
  
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "口呼吸是什麼？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "正確呼吸是鼻呼吸，舌頭會輕輕地抵在上顎的位置，嘴唇成自然閉合的狀態。而口呼吸好發於鼻塞的情況，在鼻呼吸不通順的時候，就會使用嘴巴來呼吸，這就是口呼吸。"
        }
      },
      {
        "@type": "Question",
        "name": "口呼吸是怎麼導致過敏？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "患者常因鼻呼吸不順而張嘴呼吸，長期口呼吸讓空氣中的髒污或致敏物質、與扁桃腺或腺樣體直接接觸，造成過敏或發炎反應、阻塞鼻呼吸通道，在進氣量不足情況下，又更容易張口呼吸，導致惡性循環。"
        }
      },
      {
        "@type": "Question",
        "name": "口呼吸會如何影響口腔發展？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "長期習慣口呼吸會造成舌頭位置錯誤，嘴巴長期張開會導致口唇無力，少了舌頭與嘴唇的正確施力，臉頰的力量會向內推擠，就可能造成牙弓變窄，而讓齒列開始變得擁擠。"
        }
      },
      {
        "@type": "Question",
        "name": "幼兒牙套如何改善過敏狀態？",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "改善口呼吸的習慣可以避免過敏原從口腔直接進入，恢復鼻呼吸的狀態、多加上一層鼻子的過濾屏障，降低幼兒的過敏反應，進而改善鼻塞問題、讓鼻子能正常呼吸，對於幼兒生長發育以及學習表現有重大改善。"
        }
      }
    ]
  }

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.allergy' })}>
      <Seo
        title='幼兒異位性皮膚炎，不吃藥不打針，只靠一招解決-日不落牙醫集團'
        metaDescription='3~5歲孩童近一半有過敏體質，如過敏性鼻炎、異位性皮膚炎、氣喘等，而這些問題與口呼吸有正向關聯，集結台北、新竹、台中、台南的日不落兒童牙醫專注於改善幼兒口呼吸，解決嬰幼兒過敏問題。'
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      </Helmet>
      <StyledContentTitle id='title'>
        <span>幼兒舒敏牙套</span>
        <SubTitle>(3 ~ 7歲)</SubTitle>
      </StyledContentTitle>
      <ContentSection imageUrl={allergy1Image} imageAlt='日不落牙醫集團-舒敏牙套' imagePosition='left'>
        <p>
          您家的小朋友是否也有以下問題
          <br />
          <strong>老是鼻塞，難以睡過夜？</strong>
          <br />
          <strong>手上腳上紅一塊紫一塊、紅腫發癢？</strong>
          <br />
          <br />
          經台灣小兒科統計，近一半的兒童都有過敏體質，如過敏性鼻炎、異位性皮膚炎、氣喘等，而這些問題與口呼吸有正向關聯。
        </p>
      </ContentSection>
      <ContentSection>
        <p>舒敏牙套專注於改善 3 ~ 7 歲幼兒的口呼吸問題，不僅可即早避免口呼吸造成的暴牙、齒亂等問題，更可提升孩子們睡眠品質、改善注意力、情緒控制等問題。</p>
      </ContentSection>
      <ContentSection title='舒敏牙套的三大特色'>
        <FeatureWrapper>
          <GridLayout templateColumns={['100%', 'repeat(3,1fr)']}>
            <GalleryItem maxWidth='200px' imgUrl={allergyFeature1} imgAlt='日不落牙醫集團舒敏牙套特點1-不吃藥不打針' title='不吃藥不打針' />
            <GalleryItem maxWidth='200px' imgUrl={allergyFeature2} imgAlt='日不落牙醫集團舒敏牙套特點2-不切除扁桃腺' title='不切除扁桃腺' />
            <GalleryItem maxWidth='200px' imgUrl={allergyFeature3} imgAlt='日不落牙醫集團舒敏牙套特點3-遊戲化療程' title='遊戲化療程' />
          </GridLayout>
        </FeatureWrapper>
        <p>
          <strong>❶ 不吃藥不打針</strong>：日不落的舒敏牙套崇尚自然療法，重建小朋友的正確鼻呼吸習慣，用人體本身的鼻子過濾致敏物質。
        </p>
        <p>
          <strong>❷ 不切除扁桃腺</strong>：扁桃腺不應該因為容易過敏就被切除，而是應該減少致敏物質直接透過口呼吸攻擊腺體。
        </p>
        <p>
          <strong>❸ 遊戲化療程</strong>：透過拉扯遊戲，以及口香糖獎勵，讓小朋友樂於配戴舒敏牙套。
        </p>
      </ContentSection>
      <ContentSection title='改善幼兒過敏就從改善口呼吸開始'>
        <p>
          當幼兒出現『口呼吸』的生活習慣，空氣中的致敏物質少了被鼻子的過濾，直接從口腔進入扁桃腺等腺體，就容易產生扁桃腺腫大或異位性皮膚炎等問題，導致小朋友鼻塞、吃飯慢吞吞，或是晚上打鼾、夜驚或夜尿、尿床等。
          <br />
          日不落的舒敏牙套，透過遊戲化過程，養成每日配戴牙套的習慣，不僅能讓小朋友改善口呼吸習慣，同時也會訓練小朋友的咀嚼肌肉、正確顎間關係，重新建立正確的鼻呼吸習慣。
        </p>
        <SymptomWrapper>
          <GridLayout templateColumns={['100%', 'repeat(3,1fr)']}>
            <GalleryItem maxWidth='275px' imgUrl={allergySymptom1} imgAlt='日不落牙醫集團口呼吸症狀1-改善異位性皮膚炎' title='改善異位性皮膚炎' />
            <GalleryItem maxWidth='275px' imgUrl={allergySymptom2} imgAlt='日不落牙醫集團口呼吸症狀2-預防扁桃腺發炎' title='預防扁桃腺發炎' />
            <GalleryItem maxWidth='275px' imgUrl={allergySymptom3} imgAlt='日不落牙醫集團口呼吸症狀3-改善睡眠品質' title='改善睡眠品質' />
          </GridLayout>
        </SymptomWrapper>
      </ContentSection>
      <ContentSection title='舒敏牙套實際案例'>
        <CompareImages>
          <DesktopCaseImage src={case1ImageDesktop} alt='前牙蛀牙/變色' />
          <DesktopCaseImage src={case2ImageDesktop} alt='小兔牙（輕微凌亂）' />
          <MobileCaseImage src={case1ImageMobile} alt='前牙蛀牙/變色' />
          <MobileCaseImage src={case2ImageMobile} alt='小兔牙（輕微凌亂）' />
        </CompareImages>
      </ContentSection>
      <ContentSection title='舒敏牙套的諮詢流程'>
        <GridLayout templateColumns={['100%']} rowGap={['32px', '24px']}>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step1Image} alt='step1' />
            <HowToStartWrapper>
              <H3Title>線上預約至日不落診所進行諮詢</H3Title>
              <p>可於官方諮詢管道即時諮詢並進行約診，由醫師檢查孩子的呼吸道確認是否出現扁桃腺腫脹、日常是否有口呼吸習慣，經由綜合評估後提供治療建議。</p>
            </HowToStartWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step2Image} alt='step2' />
            <HowToStartWrapper>
              <H3Title>確認進行口呼吸治療</H3Title>
              <p>與醫師溝通後確認進行口呼吸治療，先付訂金便會進行舒敏牙套的製作，在等待 2 週後的時間，就能拿到舒敏牙套，預約回到診間並接受相關衛教。</p>
            </HowToStartWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step3Image} alt='step3' />
            <HowToStartWrapper>
              <H3Title>定期回診追蹤確認治療進程</H3Title>
              <p>由父母每日陪同孩子進行訓練，養成配戴舒敏牙套的習慣，初次每隔 2 周回診，第二個月開始改為單月回診，第三個月後為每季回診，穩定後為半年回診，療程總時長約一年。</p>
            </HowToStartWrapper>
          </GridLayout>
        </GridLayout>
      </ContentSection>
      <ContentSection title='配戴舒敏牙套的治療流程' imageUrl={allergy2Image} imageAlt='配戴舒敏牙套的治療流程' imagePosition='left'>
        <p>【正確配戴舒敏牙套的方式】</p>
        <p>
          <strong>❶</strong> 先將牙套戴入下排牙齒
        </p>
        <p>
          <strong>❷</strong> 確認小朋友的舌頭有放在導舌板上方
        </p>
        <p>
          <strong>❸</strong> 再將上排牙齒咬入牙套中
        </p>
        <p>
          <strong>❹</strong> 配戴牙套時請勿說話
        </p>
      </ContentSection>
      <ContentSection>
        <p>【 配戴時間0 ~ 3個月：每日需要父母陪同練習，無須戴牙套睡覺 】</p>
        <p>
          <GrayBgContent>0 ~ 2週</GrayBgContent>拉扯運動：50下
        </p>
        <p>
          <GrayBgContent>3 ~ 4週</GrayBgContent>拉扯運動：100下
        </p>
        <p>
          <GrayBgContent>2 ~ 3月</GrayBgContent>拉扯運動：150下，有氧運動：20分鐘
        </p>
      </ContentSection>
      <ContentSection>
        <p>【 配戴時間3個月以上：可剪掉拉柄，並開始配戴過夜 】</p>
        <p>
          <GrayBgContent>戴牙套</GrayBgContent>有氧運動：持續20分鐘
        </p>
        <p>
          <GrayBgContent>戴牙套</GrayBgContent>配戴牙套過夜睡覺，或是由父母在孩子睡著後塞入口內
        </p>
      </ContentSection>
      <ContentSection title='配戴舒敏牙套的注意事項'>
        <ListGroup>
          <ListItem content='每天都要定時配戴牙套並進行訓練' />
          <ListItem content='配戴時要確認牙套戴在正確的位置' />
          <ListItem content='訓練的方式需按照醬師指示來進行' />
          <ListItem content='使用過後的牙套需要定期進行清潔' />
        </ListGroup>
      </ContentSection>
      <QuestionSection>
        <ContentSection title='幼兒舒敏牙套 常見問題'>
          <Collapse title='口呼吸是什麼？'>
            <p>正確呼吸是鼻呼吸，舌頭會輕輕地抵在上顎的位置，嘴唇成自然閉合的狀態。而口呼吸好發於鼻塞的情況，在鼻呼吸不通順的時候，就會使用嘴巴來呼吸，這就是口呼吸。</p>
          </Collapse>
          <Collapse title='口呼吸是怎麼導致過敏？'>
            <p>
              患者常因鼻呼吸不順而張嘴呼吸，長期口呼吸讓空氣中的髒污或致敏物質、與扁桃腺或腺樣體直接接觸，造成過敏或發炎反應、阻塞鼻呼吸通道，在進氣量不足情況下，又更容易張口呼吸，導致惡性循環。
            </p>
          </Collapse>
          <Collapse title='口呼吸會如何影響口腔發展？'>
            <p>長期習慣口呼吸會造成舌頭位置錯誤，嘴巴長期張開會導致口唇無力，少了舌頭與嘴唇的正確施力，臉頰的力量會向內推擠，就可能造成牙弓變窄，而讓齒列開始變得擁擠。</p>
          </Collapse>
          <Collapse title='幼兒牙套如何改善過敏狀態？'>
            <p>
              改善口呼吸的習慣可以避免過敏原從口腔直接進入，恢復鼻呼吸的狀態、多加上一層鼻子的過濾屏障，降低幼兒的過敏反應，進而改善鼻塞問題、讓鼻子能正常呼吸，對於幼兒生長發育以及學習表現有重大改善。
            </p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default Allergy
