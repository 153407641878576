/* eslint-disable max-len */
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled, { css } from 'styled-components'

import BannerImage from '../../assets/static/banner/banner_guting.jpg'
import EnvironmentImg1 from '../../assets/static/clinic/guting_environment1.jpg'
import EnvironmentImg2 from '../../assets/static/clinic/guting_environment2.jpg'
import EnvironmentImg3 from '../../assets/static/clinic/guting_environment3.jpg'
import EnvironmentImg4 from '../../assets/static/clinic/guting_environment4.jpg'
import EnvironmentImg5 from '../../assets/static/clinic/guting_environment5.jpg'
import EnvironmentImg6 from '../../assets/static/clinic/guting_environment6.jpg'
import consultImage from '../../assets/static/consult/consult1.jpg'
import Button from '../../components/Button'
import { getClinicInfo } from '../../components/Clinic/getClinicInfo'
import { GridItem, GridLayout } from '../../components/Grid'
import ClinicLayout from '../../components/Layout/ClinicLayout'
import Seo from '../../components/Seo'
import { space } from '../../constants/length'
import { Hsinchu, Taipei, Guting, GutingSecondary } from '../../constants/newColor'
import { Body, H2 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import { Location } from '../../types/types'
import CaseSection from './components/CaseSection'
import Consult from './components/Consult'
import DoctorSection from './components/Doctor'
import GoogleMap from './components/GoogleMap'
import LogoInfo from './components/LogoInfo'
import OtherClinic from './components/OtherClinic'
import TimetableSection from './components/Timetable'
import { gutingTimetableData } from './components/Timetable/staticData'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleDecStyle = css`
  width: 8px;
  height: 1.85rem;
  border-radius: 24px;
  margin: 0 ${space.m}px;
`

const ContentTitle = styled.h2`
  ${H2}
  display: flex;
  align-items: center;
  margin: 0 auto ${space.l}px auto;

  &:before {
    ${TitleDecStyle}
    content: '';
    display: block;
    background-color: ${GutingSecondary};
  }
  &:after {
    ${TitleDecStyle}
    content: '';
    display: block;
    background-color: ${Guting};
}
`

const Img = styled.img`
  width: 100%;
  object-fit: cover;
`

const Info = styled.div`
  ${Body}
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${viewport.tablet}px) {
    max-width: 723px;
    margin: auto;
    margin-bottom: 100px;
  }
`

const MoreCase = styled.a`
  display: inline-block;
  margin-top: ${space.xl}px;
`

const ConsultWrapper = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: ${viewport.desktop}px) {
    height: 406px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    height: 294px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 200px;
  }
`

const ClinicGuting = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.clinic' }),
      path: '/clinic'
    },
    {
      label: formatMessage({ id: 'clinic.guting' }),
      path: '/clinic/guting'
    }
  ]

  return (
    <ClinicLayout imageUrl={BannerImage} pageBannerInfo={formatMessage({ id: 'banner.guting' })} routes={routes} {...props}>
      <Seo
        title='【古亭中永和牙醫推薦】蒔穗美學牙醫診所：隱形牙套、矯正專科、SOV特約'
        metaDescription='台北古亭蒔穗美學牙醫診所，位於捷運綠線、黃線古亭7號出口，鄰近師大、公館商圈，可服務台北萬華區、新店中永和推薦牙醫，擁有台大、北醫多位矯正專科醫師，提供隱形牙套、戴蒙矯正、陶瓷貼片、人工植牙等牙科治療，「對待您如同家人朋友」是日亞美服務的堅持。'
      />
      <LogoInfo location={Location.Guting} />
      <Content>
        <GridLayout templateColumns={['100%']} templateAreas={[`'imgSection1' 'info' 'imgSection2'`, `'info''imgSection1''imgSection2'`]} rowGap={['24px']}>
          <GridItem area='imgSection1'>
            <GridLayout templateColumns={['repeat(3, 1fr)']} columnGap={['9px', '24px']}>
              <Img src={EnvironmentImg1} alt='古亭蒔穗美學牙醫診所環境照1' />
              <Img src={EnvironmentImg2} alt='古亭蒔穗美學牙醫診所環境照2' />
              <Img src={EnvironmentImg3} alt='古亭蒔穗美學牙醫診所環境照3' />
            </GridLayout>
          </GridItem>
          <GridItem area='info'>
            <ContentTitle>關於我們</ContentTitle>
            <Info>
              <p>『蒔穗歡慶日不落牙醫集團十周年』</p>
              <p>
                古亭蒔穗美學牙醫診所位於捷運古亭站7號出口，路易莎旁電梯上3樓，鄰近師大、公館商圈，可服務捷運綠線及黃線、萬華區推薦、新店中永和推薦牙醫。蒔穗美學牙醫聘請台大、北醫牙醫師駐診，承襲日本的服務精神與台灣的專業牙科治療，導入牙科數位化「3shape 口內掃描儀」，以及 MIT 第一品牌「SOV 數位隱形矯正系統」，廣邀各專科權威，提供給您最專業、舒適的醫療環境。
              </p>
            </Info>
          </GridItem>
          <GridItem area='imgSection2'>
            <GridLayout templateColumns={['repeat(3, 1fr)']} columnGap={['9px', '24px']}>
              <Img src={EnvironmentImg4} alt='古亭蒔穗美學牙醫診所環境照4' />
              <Img src={EnvironmentImg5} alt='古亭蒔穗美學牙醫診所環境照5' />
              <Img src={EnvironmentImg6} alt='古亭蒔穗美學牙醫診所環境照6' />
            </GridLayout>
          </GridItem>
        </GridLayout>
      </Content>
      <Content>
        <ContentTitle>專業團隊</ContentTitle>
        <DoctorSection page={Location.Guting} />
      </Content>
      <Content>
        <ContentTitle>矯正心得分享</ContentTitle>
        <CaseSection location={[Location.Guting]} />
        <MoreCase href={`/case?location=${Location.Guting}`}>
          <Button label='MORE &#9658;' type='secondary' />
        </MoreCase>
      </Content>
      <Content>
        <ConsultWrapper>
          <Consult color={Guting} imageUrl={consultImage} />
        </ConsultWrapper>
      </Content>
      <Content>
        <ContentTitle>門診資訊</ContentTitle>
        <TimetableSection
          data={gutingTimetableData}
          primaryColor={Guting}
          secondaryColor={GutingSecondary}
          oddColor={Taipei}
          evenColor={Hsinchu}
          tel={getClinicInfo({ location: Location.Guting, value: 'tel' })}
          line={getClinicInfo({ location: Location.Guting, value: 'socialMedia_line_info' })}
        />
      </Content>
      <Content id='location'>
        <GoogleMap lat={25.02775} lng={121.5216} title='sunlight_guting' add={getClinicInfo({ location: Location.Guting, value: 'address' })} />
      </Content>
      <Content>
        <ContentTitle>其他診所</ContentTitle>
        <OtherClinic />
      </Content>
    </ClinicLayout>
  )
}

export default ClinicGuting
