import GutingLogo from '../../assets/images/clinic_guting.svg'
import HsinchuLogo from '../../assets/images/clinic_hsinchu.svg'
import TaichungLogo from '../../assets/images/clinic_taichung.svg'
import TainanLogo from '../../assets/images/clinic_tainan.svg'
import TaipeiLogo from '../../assets/images/clinic_taipei.svg'
import GutingCaseBackgroundImage from '../../assets/static/clinic/guting_case_background.png'
import HsinchuCaseBackgroundImage from '../../assets/static/clinic/hsinchu_case_background.png'
import TaichungCaseBackgroundImage from '../../assets/static/clinic/taichung_case_background.png'
import TainanCaseBackgroundImage from '../../assets/static/clinic/tainan_case_background.png'
import TaipeiCaseBackgroundImage from '../../assets/static/clinic/taipei_case_background.png'

import { Hsinchu, Taichung, Tainan, Taipei, Guting, Primary } from '../../constants/newColor'
import { Location } from '../../types/types'

interface ClinicDataType {
  title: string
  route: string
  value: Location
  lineBtnValue: string
  name: string
  footerOptionName: string
  tags: string[]
  primaryColor: string
  logoImageUrl: string
  caseBackgroundImageUrl: string
  address: string
  tel: string
  email: string
  socialMedia_fb: string
  socialMedia_ig: string
  socialMedia_line_info: string
  socialMedia_line_footer: string
  hassite: boolean
}
type Area = '北部' | '中部' | '南部' | '其他'
interface Props {
  area: Area
  clinics: ClinicDataType[]
}

const clinicInfoList: Props[] = [
  {
    area: '北部',
    clinics: [
      {
        title: 'taipei',
        route: '/clinic/taipei',
        value: Location.Taipei,
        lineBtnValue: '台北',
        name: '日亞美牙醫診所',
        footerOptionName: '台北日亞美牙醫診所',
        tags: ['台北東區', '忠孝敦化站'],
        primaryColor: Taipei,
        logoImageUrl: TaipeiLogo, 
        caseBackgroundImageUrl: TaipeiCaseBackgroundImage, 
        address: '台北市大安區敦化南路一段219號4樓',
        tel: '02-2771-3727',
        email: 'cdctaipei2@gmail.com',
        socialMedia_fb: 'https://www.facebook.com/sunlight.taipei/',
        socialMedia_ig: 'https://instagram.com/sunlight.taipei',
        socialMedia_line_info: 'https://lin.ee/z2LvV0Z',
        socialMedia_line_footer: 'https://lin.ee/kdx1L7t',
        hassite: true
      },
      {
        title: 'guting',
        route: '/clinic/guting',
        value: Location.Guting,
        lineBtnValue: '古亭',
        name: '蒔穗美學牙醫診所',
        footerOptionName: '古亭蒔穗美學牙醫診所',
        tags: ['台北古亭', '天才領袖3F'], 
        primaryColor: Guting,
        logoImageUrl: GutingLogo, 
        caseBackgroundImageUrl: GutingCaseBackgroundImage,
        address: '台北市中正區羅斯福路二段56號3樓',
        tel: '02-2356-9795',
        email: 'sunlight.10y@gmail.com',
        socialMedia_fb: 'https://www.facebook.com/sunlight.10y',
        socialMedia_ig: 'https://www.instagram.com/sunlight.10y',
        socialMedia_line_info: 'https://lin.ee/VuRb54M',
        socialMedia_line_footer: 'https://lin.ee/LBUUqo6',
        hassite: true
      },
      {
        title: 'hsinchu',
        route: '/clinic/hsinchu',
        value: Location.Hsinchu,
        lineBtnValue: '新竹',
        name: '橙蒔美學牙醫診所',
        footerOptionName: '新竹橙蒔美學牙醫診所',
        tags: ['竹北牙醫首選', '新竹遠百'],
        primaryColor: Hsinchu,
        logoImageUrl: HsinchuLogo,
        caseBackgroundImageUrl: HsinchuCaseBackgroundImage,
        address: '新竹縣竹北市成功三路92號',
        tel: '03-658-9988',
        email: 'smile6589988@gmail.com',
        socialMedia_fb: 'https://www.facebook.com/sunlight.hsinchu',
        socialMedia_ig: 'https://www.instagram.com/sunlight.hsinchu/',
        socialMedia_line_info: 'https://lin.ee/LoSUPAz',
        socialMedia_line_footer: 'https://lin.ee/KqUsxWZ',
        hassite: true
      }
    ]
  },
  {
    area: '中部',
    clinics: [
      {
        title: 'taichung',
        route: '/clinic/taichung',
        value: Location.Taichung,
        lineBtnValue: '台中',
        name: '日蒔美學牙醫診所',
        footerOptionName: '台中日蒔美學牙醫診所',
        tags: ['台中七期', '文心路屋馬旁'],
        primaryColor: Taichung,
        logoImageUrl: TaichungLogo,
        caseBackgroundImageUrl: TaichungCaseBackgroundImage,
        address: '台中市南屯區文心路一段498號',
        tel: '04-2320-0311',
        email: 'sunlight.taichung@gmail.com',
        socialMedia_fb: 'https://www.facebook.com/sunlight.taichung',
        socialMedia_ig: 'https://instagram.com/sunlight.taichung/',
        socialMedia_line_info: 'https://lin.ee/2gW6cXj',
        socialMedia_line_footer: 'https://lin.ee/48tis46',
        hassite: true
      }
    ]
  },
  {
    area: '南部',
    clinics: [
      {
        title: 'tainan',
        route: '/clinic/tainan',
        value: Location.Tainan,
        lineBtnValue: '台南',
        name: '蒔光美學牙醫診所',
        footerOptionName: '台南蒔光美學牙醫診所',
        tags: ['台南東區', '長榮女中旁'],
        primaryColor: Tainan,
        logoImageUrl: TainanLogo,
        caseBackgroundImageUrl: TainanCaseBackgroundImage,
        address: '臺南市東區長榮路二段169號',
        tel: '06-2088774',
        email: 'sunlight.tainan@gmail.com',
        socialMedia_fb: 'https://www.facebook.com/sunlight.tainan/',
        socialMedia_ig: 'https://www.instagram.com/sunlight.tainan/',
        socialMedia_line_info: 'https://lin.ee/D3c3inA',
        socialMedia_line_footer: 'https://lin.ee/JptPsTn',
        hassite: true
      }
    ]
  },
  {
    area: '其他',
    clinics: [
      {
        title: 'others',
        route: '/',
        value: Location.Others,
        lineBtnValue: '其他',
        name: '日不落加盟診所',
        footerOptionName: '日不落加盟診所',
        tags: [''],
        primaryColor: Primary,
        logoImageUrl: '',
        caseBackgroundImageUrl: '',
        address: '',
        tel: '',
        email: 'neilchu@sov.dental, info@sov.dental',
        socialMedia_fb: '',
        socialMedia_ig: '',
        socialMedia_line_info: '',
        socialMedia_line_footer: 'https://lin.ee/S3MRZ6a',
        hassite: false
      }
    ]
  }
]

export default clinicInfoList
